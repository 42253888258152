.auth {
    position: absolute;
    top: 50%; 
    left: 50%;
    transform: translate(-50%,-50%);  
}

.auth-title {
    background: $tc;
    border-radius: $radius;
    z-index: 900;
    width: 20em;
    padding: $lh;
    backdrop-filter: blur(6px);
    filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.5);
    text-align: center;
    padding-bottom: $lh;
    display: block;
    margin-bottom: $lh;
}

.auth-3d {
    background: linear-gradient(90deg,var(--color) 24%,var(--color2) 43%,var(--color2) 63%,var(--color2) 80%,var(--color) 100%);
    animation: highlight 9s linear infinite;
    background-size: 200% 100%; 
    height: 10em;
}

.auth-title:hover {
    filter: drop-shadow(0px 0px 0px rgba(0,0,0,0.8));
}

@keyframes highlight{0%{background-position:-20%}to{background-position:180%}}

:root {
    --color2: red;
    --color: black;
  }