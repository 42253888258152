.nav-button {
    display: inline-block;
    padding: $lh / 4;
    text-decoration: none;
    filter: drop-shadow(0px 0px 4px rgba(255,255,255,0.8));
    box-shadow: inset 0px 0px 10px rgba(255,255,255,0.5);
    border-radius: $radius * 2;
    margin-top: $lh / 4;
    margin-left: $lh / 4;
    color: $tc;
    font-size: 1.4rem;

    @media only screen and (max-width: 750px) {
        font-size: 1.1rem;
    }
 
}

.nav-button-small {
}

.nav-button-closed {
    display: inline-block;
    padding: $lh / 4;
    text-decoration: none;
    background: black;
    filter: drop-shadow(0px 0px 4px rgba(255,255,255,0.8));
    box-shadow: inset 0px 0px 10px rgba(255,255,255,0.5);
    border-radius: $radius * 2;
    margin-top: $lh / 4;
    margin-left: $lh / 4;
    color: white;
    font-size: $medium;
    font-size: $big;
    background-size: 200% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 0.5;

    @media only screen and (max-width: 750px) {
        font-size: 1.1rem
    }
}

.nav-button-is-active {
    background: linear-gradient(90deg,var(--color) 24%,var(--color2) 43%,var(--color2) 63%,var(--color2) 80%,var(--color) 100%);
    animation: highlight 9s linear infinite;
    background-size: 200% 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    outline-color: var(--color);
}

.nav-button-closed, .nav-button:hover {
    transform: scale(0.95);
    transition: 0.4s transform;
}

.chat-content-message {
    margin-top: 0.1em;
    font-size: 0.9em;
}

.nav-is-live {
    // background: rgba(252, 0, 0, 0.5);
    width: $lh / 2;
    height: $lh / 2;
    display: block;
    position: absolute;
    border-radius: 3em;
    top: 50%;
    left: 1em;
    transform: translate(-50%,-50%);
    // filter: drop-shadow(0px 0px 4px rgba(255,255,255,0.8));
    // box-shadow: inset 0px 0px 10px rgba(255, 0, 0, 1);
    animation: blinks 5s linear infinite;
}


// @keyframes blinks {
//     0%   {background-color: rgba(255, 0, 0, 0.103);}
//     25%  {background-color: yellow;}
//     50%  {background-color: blue;}
//     100% {background-color: rgba(0, 128, 0, 0.37);}
//   }

@keyframes blinks {
    0%   {background-color: rgba(255, 255, 255, 1);}
    25%  {background-color: rgba(255, 0, 0, 0);}
    50% {background-color: rgb(255, 0, 0);}
    70%  {background-color: rgba(255, 0, 0, 0);}
    100%   {background-color: rgba(255, 255, 255, 1);}
  }