.chat {
    width: 13.5em;
    // // max-height: 33vh;
    position: fixed;
    bottom: 4em;
    right: $lh / 4;
    // border-top: 2px solid #8020fd;
    height: calc(100% - 4.35em);
    padding: $lh / 4;
    // z-index: 11;
    border-radius: $radius / 2;
    overflow: hidden;
    // background: $g1;
    // filter: drop-shadow(0px 0px 4px rgba(0,0,0,0.8));
    box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
    z-index: 500;
    backdrop-filter: blur(0.5em);
    background: rgba(0, 0, 0, 0.5);
}

.chat-status {
    margin-top: $lh / 2;
    background: rgba(0, 0, 0, 0.5);
    border-radius: $radius;
    position: absolute;
    bottom: $lh / 4;
    padding: $lh / 4;
    padding-left: $lh / 2;
    padding-right: $lh / 2;
    left: $lh * 4 + 0.4em;
    z-index: 1000;
    position: fixed;
    font-size: 1.25rem;

    a {
        color: white;
        margin-right: $lh / 2;
        text-decoration: none;
        opacity: 0.5;
        border-bottom: 2px solid;
        padding-bottom: 0.07em;
        cursor: pointer;
    }

    @media only screen and (max-width: 750px) {
        bottom: $lh * 1.8;
        left: $lh / 4;
    }
}

.chat-avatar {
    width: $lh * 2;
    height: $lh * 2;
    display: inline-block;
    border-radius: 1000em;
    overflow: hidden;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));
    // border: 2px solid white;

    img {
        width: 100%;
        height: 100%;
    }
}

.chat-version {
    font-size: 0.5em;
    margin-bottom: 0.5em;
}

.chat-title {
    padding: $lh / 4;
    margin-left: $lh / 4;
    z-index: 500;
    display: inline-block;
    height: auto;
    min-width: 10em;
    vertical-align: top;
    border-radius: $radius / 4;
    overflow: hidden;
    max-width: 11em;
    margin-bottom: $lh / 8;
}

.footer-link {
    border: none;
    text-decoration: none;
    color: white;
    display: inline-block;
    margin-right: $lh / 4;
    margin-top: $lh / 4;
    background: none;
}

.footer-name {
    display: inline-block;
}

.chat-status-profile {
    display: inline-block;
    img {
        margin-left: 1em;
        width: 1.4em;
        height: 1.4em;
        border-radius: 5em;
        vertical-align: middle;
    }
}

.chat-bar {
    position: absolute;
    bottom: $lh / 4;
    left: $lh / 4;
    width: 100%;

    .chat-input-submit {
        background: $tc;
        padding-left: 0.5em;
        margin-left: 0.2em;
    }
    .chat-input-field{
        width: 10em;
    }
}

.chat-messages {
    overflow: scroll;
    height: calc(100vh - 8em);
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
    margin-bottom: 0;
    pointer-events: ignore;
}

.chat-name {
    font-size: 0.8em;
    opacity: 0.5;
}

.chat-message {
    margin-bottom: $lh / 2;

}
