.header {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%,0);
    color: $pc;
    padding: $lh / 4;
    padding-top: $lh / 8;
    z-index: 100;
    user-select: none;
    color: $pc;
    width: 70vw;

    @media only screen and (max-width: 750px) {
        width: 100%;
        font-size: 0.8em;
        left: 0;
        transform: translate(0,0);
        padding-left: 7.5em;;
    }
}


.footer-mute {
    display: inline-block;
    position: fixed;
    bottom: $lh / 4;
    left: $lh / 4;
    z-index: 900;
    font-size: $medium;
    color: $pc;
    user-select: none;
    cursor: pointer; 
    background: $tc;
    padding: $lh / 4;
    border-radius: $radius * 2;
    color: black;
}

.chat-togle {
    display: inline-block;
    position: fixed;
    bottom: $lh / 4;
    right: $lh / 4;
    z-index: 900;
    font-size: $medium;
    color: $pc;
    user-select: none;
    cursor: pointer; 
    background: $tc;
    // padding: $lh / 4;
    border-radius: $radius * 2;
    background: rgba(0,0,0,0.5);
    font-size: 1.25rem;
    color: white;
    padding-right: $lh / 2;
}

.footer-right {
    position: fixed;
    bottom: 0;
    left: 0;
    color: white;
    font-size: $big;
    padding: $lh / 4;
    padding-top: 0;
    background: white;
    color: black;
    margin: $lh / 4;
    border-radius: $radius / 8;
    opacity: 0.5;
    cursor: pointer;
    user-select: none;
}

.footer-left {
    position: fixed;
    bottom: 0.5em;
    left: 0;
    color: white;
    margin: $lh / 4;
    padding: $lh / 4;
    user-select: none;
    font-size: 1em;
    z-index: 100;
}

.header-container {
    position: relative;
}

.flashmessage-container {
    text-align: left;

}
.flashmessage {
    padding: $lh /2;
    // background: rgba(255,255,255,0.1);
    margin-top: $lh /4;
    display: inline-block;
    border-radius: $radius;
    // // filter: drop-shadow(0px 0px 4px rgba(255,255,255,0.8));
    // box-shadow: inset 0px 0px 10px rgba(255,255,255,0.5);
}