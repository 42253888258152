.profile-container {
    margin: $lh;
    padding: $lh / 2;
    width: 20em;
    background: $tc;
    border-radius: $radius;
    position: absolute;
    left: 50%;
    top: $lh * 4;
    transform: translate(-50%,0);
}

.profile-image-container {
    margin-top: $lh;
}

.profile-upload {
    font-size: inherit;
    // background: $sc;
    // border: none;
    // border-radius: $lh / 8;
    // padding: $lh / 2;
    display: inline-block;
    margin-bottom: $lh;
    margin-top: $lh / 2;
}

input {
    font-size: inherit;
    border: none;
    border-radius: $lh / 8;
    margin-top: $lh / 4;
    padding: $lh / 4;
}

.profile-button {
    font-size: inherit;
    background: #f003ff;
    border: none;
    border-radius: $lh / 8;
    padding: $lh / 2;
    color: white;
    margin-right: $lh /2
}

.profile-button-inactive {
    opacity: 0.5;
}