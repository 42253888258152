$pc: white;
$sc: darkgrey;
$tc: red;



$radius: 0.5em;
$lh: 1.4em;
$big: 1.55rem;
$medium: 1.25rem;


$g1: linear-gradient(90deg, #00ff00 0%, black 35%, #00ff00 100%);