.menu-container {
    position: fixed;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    z-index: 110;
    padding: $lh;
    overflow: auto;
    border-left: 2px solid white;
    color: white;
    backdrop-filter: brightness(50%) blur(10px);
    // border-bottom-left-radius: $radius;
    // border-top-left-radius: $radius;
    background: black;

    @media only screen and (max-width: $firsbrekpoint) {
        width: 100%; 
        left: 0   
    }
}

.babylonUnmuteIcon {
    left: 1em !important;
    top: 10% !important;
    // transform: translate(-50%,-50%);
    border-radius: $radius / 2;
    width: 8em !important;
    height: 5.4em !important;
}

.menu-animation-enter {
    opacity: 0;
    transition: all 200ms;
}

.menu-animation-enter-active {
    opacity: 1;
}
.menu-animation-enter-done {
    opacity: 1;
}

.menu-animation-exit {
    opacity: 1;
    transition: all 200ms;
}
.menu-animation-exit-active {
    opacity: 0;
    transition: all 200ms;
}

.menu-text {
    font-size: 1.38em;
}

.menu-text-cz {
    font-size: 1.38em;
    padding-left: $lh * 2;
}

.youth {
    font-family: youth;
    font-size: 1.1em;
}

.menu-credits-cz {
    padding-left: $lh * 2;
}

.menu-credits {
    margin-top: $lh * 4;
}

.menu-credits-adela {
    margin-top: $lh;
}

.menu-status {
    margin-top: $lh;
}